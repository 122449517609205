import React from 'react';
import type { FC } from 'react';
import { Container, Typography, makeStyles, createStyles } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { Layout } from '../components/layouts/Layout';

const Page: FC = () => {

  const styles = makeStyles((theme) => createStyles({
    container: {
      margin: theme.spacing(3),
      padding: theme.spacing(1),
    },
    line: {
      lineHeight: '3em',
    },
  }))();

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About - QSL Print.com</title>
        <meta name="description" content="ログをアップロードするだけでQSLカードの宛先面がPDFに！コンビニのマルチコピー機や自宅のプリンタで印刷できます。" />
        <meta name="keywords" content="アマチュア無線,QSLカード,宛先,印刷"/>
        <link rel="canonical" href="https://www.qsl-print.com/about/" />
      </Helmet>

      <Typography variant="h1">QSL Print.comとは</Typography>

      <Container className={styles.container}>
        <Typography className={styles.line}>
          アマチュア無線の楽しみの一つにQSLカード交換があります。
        </Typography>
        <Typography className={styles.line}>
          電子QSLも広まった昨今ではありますが、紙のQSLカードの需要は少なくありません。
        </Typography>
        <Typography className={styles.line}>
          QSLカードの記載事項はどのように記入していますか？
        </Typography>
        <Typography className={styles.line}>
          自宅のプリンターを使う方が多いと思いますが、インク・用紙など消耗品の用意、本体の手入れなど、手間がかかります。
        </Typography>
        <Typography className={styles.line}>
          また、プリンターを持っていないので手書きにするしかないという方もいらっしゃるのではないでしょうか。
        </Typography>
        <Typography className={styles.line}>
          QSL Print.comは、アップロードされた交信ログをQSLカードに変換してPDFとして出力します。
        </Typography>
        <Typography className={styles.line}>
          そのデータをコンビニのマルチコピー機に入力すれば、1枚あたり20〜30円ほどで綺麗に印刷できます。
        </Typography>
        <Typography className={styles.line}>
          プリンターもインクも用紙も必要ありません。
        </Typography>
        <Typography className={styles.line}>
          QSL Print.comを使って、手軽にQSLカードを印刷してみませんか？
        </Typography>
        <Typography className={styles.line}>
          少しでも皆さんのハムライフのお役に立てたら幸いです。
        </Typography>
      </Container>

    </Layout>
  );
}
export default Page;
